<template>
  <div>
    <title>SIM INVENTORY ~ ADD MATERIAL PRODUCT</title>
    <section class="content-header">
      <h1>
        Add material product.
        <br />
        <h4>Please enter add material product.</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Add material product.</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Add material product.</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.produkno"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Type</label>
                  <select
                    @change="veiwMaterial($event)"
                    class="form-control"
                    v-model="isidata.tipebarang"
                    id="tipebarang"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="tipebrg in barangtipe"
                      :key="tipebrg.tipe"
                      :value="tipebrg.tipe"
                    >
                      {{ tipebrg.tipe }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-save"></i>
              <h3 class="box-title">Add material product.</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Item Code"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    type="button"
                    @click="searchdata()"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <br />
              <p></p>
              <div class="table-responsive">
                <table
                  width="100%"
                  id="mydata"
                  class="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>#</th>
                      <th width="20%">Kode Barang</th>
                      <th width="30%">Nama Barang</th>
                      <th width="10%">Qty</th>
                      <th width="10%">Unit</th>
                      <th width="30%">Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.ID"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.idx }}</td> -->
                      <td>
                        <button
                          @click="saveData(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                      <td>
                        <label style="display:none">
                          {{ classdata.id }}
                        </label>
                        {{ classdata.kode_barang }}
                      </td>
                      <td>
                        {{ classdata.nama_barang }}
                      </td>
                      <td>
                        <input
                          :id="'inp-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.jumlah"
                          :name="'nm-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          :id="'inps-' + classdata.id"
                          type="text"
                          class="form-control"
                          v-model="classdata.satuan"
                          :name="'nms-' + classdata.id"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          :id="'inpc-' + classdata.id"
                          class="form-control"
                          v-model="classdata.catatan"
                          :name="'nmc-' + classdata.id"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
            <div class="box-footer">
              <router-link to="/produk">
                <button class="btn btn-success">
                  <i class="fa fa-angle-double-left"></i> Back
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-12"></div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      barangtipe: [],
      list_paged: [],
      namesearch: "",
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      isidata: {
        produkno: "",

        tipebarang: ""
      },
      classdata: {
        id: "",
        kode_barang: "",
        nama_barang: "",
        jumlah: "",
        catatan: "",
        satuan: ""
      }
    };
  },
  created() {
    this.getbarangtipe();
    this.isidata.produkno = sessionStorage.getItem("noprd");
  },
  methods: {
    searchdata() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const brgtype = this.isidata.tipebarang;
      // console.log(brgtype);
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang_by_tipe?length=" +
        this.pageSize +
        "&tipe=" +
        brgtype +
        "&cari=" +
        this.namesearch;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          //   console.log(this.count);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    veiwMaterial(event) {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const brgtype = event.target.value;
      const urlAPIget =
        this.$apiurl +
        "barang/getalldatabarang_by_tipe?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&tipe=" +
        brgtype;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          //   console.log(resp);
          this.list_paged = resp.data.data;
          this.count = resp.data.datatotalcount;
          //   console.log(this.count);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    getbarangtipe() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.barangtipe = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    saveData: function(classdata) {
      this.loading = true;
      var nop = this.isidata.produkno;
      var jml = classdata.jumlah;
      var catatanp = this.isidata.catatan;
      var sat = this.isidata.satuan;

      if (nop == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jml == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanp == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (sat == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produk: this.isidata.produkno,
          kode_barang: classdata.kode_barang,
          qty: classdata.jumlah,
          notes: classdata.catatan,
          unit: classdata.satuan,
          kode_user: kodeuser
        };
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIsavedata = this.$apiurl + "produk_d_bom/saveproduk_d_bom";

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              sessionStorage.removeItem("noprd");
              this.loading = false
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>

<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
